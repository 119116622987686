<template>
  <div class="main">
    <el-row>
      <el-col :span="20" :push="1">
        <div class="avatarlogo">
          <el-upload
            class="avatar-uploader"
            ref="upload"
            :action="upfile"
            :auto-upload="false"
            :on-change="handlechange"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <img v-if="imageUrl" :src="imageUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <div class="avatar_123">
              <p style="color:#fff">点击修改</p>
            </div>
          </el-upload>
        </div>
        <h3>个人信息</h3>
        <el-divider></el-divider>
        <div class="kefan">
          <div class="form">
            <el-form
              :label-position="labelPosition"
              status-icon
              label-width="120px"
              :model="form"
              :rules="rules"
              ref="form"
            >
              <!-- <el-form-item label="昵称" prop="nickname">
                <el-input v-model="form.nickname"></el-input>
              </el-form-item> -->
              <el-form-item label="真实姓名">
                <el-input v-model="form.name" disabled></el-input>
              </el-form-item>
              <!-- <el-form-item label="性别">
                <el-radio-group v-model="form.sex" disabled>
                  <el-radio label="1">男</el-radio>
                  <el-radio label="2">女</el-radio>
                </el-radio-group>
              </el-form-item> -->
              <el-form-item label="所属园区">
                <el-input v-model="form.yname" disabled></el-input>
              </el-form-item>
              <el-form-item label="所属部门">
                <el-input v-model="form.bname" disabled></el-input>
              </el-form-item>
              <el-form-item label="职位">
                <el-input v-model="form.work" disabled></el-input>
              </el-form-item>
              <el-form-item>
                <el-button @click="submitForm('form')" type="primary"
                  >立即修改</el-button
                >
              </el-form-item>
            </el-form>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import axios from "axios";
import config from "../config"
let baseurl = config.API_TOOl_IMG
export default {
  data() {
    return {
      labelPosition: "left",
      yuanqu: [],
      bumen: [],
      form: {
        name: "",
        value: "",
        val: "",
      },
      rules: {
        nickname: [
          { min: 0, max: 6, message: "长度为0-6个字符", trigger: "blur" },
        ],
      },
      imageUrl: "",
      upfile: this.$host + this.$api.up,
    };
  },
  methods: {

  
    // 初始化个人信息
    inituserinfo() {
      let info = JSON.parse(localStorage.getItem("userinfo"));
      this.form.name = info.name;
      this.form.yname = info.yname;
      this.form.bname = info.bname;
      this.form.work = info.work;
      this.imageUrl = info.icon;
      console.log(this.form)
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$refs.upload.submit();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handlechange(file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = baseurl+file.response
      axios({
            method: "post",
            url: this.$host + this.$api.alteruserinfo,
            data: {
              token: localStorage.getItem("token"),
              img:this.imageUrl
            },
          }).then((res) => {
            if (res.data == "ok") {
              this.$message.success("修改成功");
              this.$router.push({ path: "/" });
            } else {
              this.$message.error("修改失败，联系王家豪");
            }
          });
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
  },
  mounted() {
    this.inituserinfo();
  },
};
</script>
<style scoped>
.avatarlogo {
  display: block;
  overflow: hidden;
  width: 178px;
  height: 178px;
  margin: 0 auto;
  position: relative;
}
.avatar-uploader i.el-icon-plus.avatar-uploader-icon {
  border: 1px dashed #ccc;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
}
.avatar-uploader i.el-icon-plus.avatar-uploader-icon:hover {
  border-color: #fff;
  background: #00000070;
  color: #fff;
  transition: all 0.3s ease;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
  overflow: hidden;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
  border-radius: 50%;
  transition: all 0.1s ease;
  position:absolute;
  top:0px;
  left:0px;
}
.avatar_123{
  width: 178px;
  height: 178px;
  display: block;
  border-radius: 50%;
  transition: all 0.1s ease;
  position:absolute;
  top:0px;
  left:0px;
  opacity: 0;
  text-align: center;
  line-height: 178px;
}
.avatar_123:hover{
  opacity: 0.5;
  background-color: #000;
  transition: all 0.1s ease;
}
.avatar:hover .avatar-uploader i.el-icon-plus.avatar-uploader-icon{
  z-index: 10000;
  transition: all 0.1s ease ;
  color: #000000;
}
.main .kefan {
  display: flex;
  align-items: center;
  justify-content: center;
}
.form {
  width: 70%;
}
.el-divider--horizontal {
  width: 80px;
  margin: 5px 0;
  height: 3px;
  background-color: #4fc08d;
  box-shadow: 0px 3px 7px #4fc08d;
}
.foot {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>